import Header from '../../landing-page/Header-pendaftaran'
import Footer from '../../landing-page/Footer'

const Layout = () => {
    return (
        <div>
            <Header/>
            <Footer/>
        </div>
    )
}
export default Layout