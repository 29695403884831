import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Loading from "./Loading";
// import FormPendaftaran from "../view/pendaftaran-online/FormPendaftaran";
// import PendaftaranOnline from "../view/pendaftaran-online/PendaftaranOnline";
// import Page_404 from "../Page_404";

const Home = lazy(() => import('../view/Home'))
const PendaftaranOnline = lazy(() => import('../view/pendaftaran-online/PendaftaranOnline'))
const FormPendaftaran = lazy(() => import('../view/pendaftaran-online/FormPendaftaran'))

const Page_404 = lazy(() => import('../Page_404'))
const Content = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Suspense fallback={<Loading />}>
          <Home />
        </Suspense>
      </Route>
      <Route exact path="/pendaftaran-online">
        <Suspense fallback={<Loading />}>
          <PendaftaranOnline />
        </Suspense>
      </Route>
      <Route exact path="/form-pendaftaran">
        <Suspense fallback={<Loading />}>
          <FormPendaftaran />
        </Suspense>
      </Route>
      <Route exact path="/layanan">
        <Suspense fallback={<Loading />}>
          <Page_404 />
        </Suspense>
      </Route>

    </Switch>
  );
};

export default Content
