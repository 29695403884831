import React from "react";
import Layout from "./layout/landing-page/Layout";
import Page_404 from "./layout/Page_404";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PendaftaranOnline from "./layout/view/pendaftaran-online/Layout";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Layout} />
        <Route exact path="/pendaftaran-online" component={PendaftaranOnline} />
        <Route exact path="/form-pendaftaran" component={PendaftaranOnline} />
        <Route component={Page_404} />
      </Switch>
    </BrowserRouter>
  );
};
export default App;
