import React, { Component } from "react";
import Header from "./landing-page/Header";
import Footer from "./landing-page/Footer";
import { Link } from "react-router-dom";

export default class Page_404 extends Component {
  render() {
    return (
      <div>
        <Header />
        <main>
          <section className="vh-100 d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center d-flex align-items-center justify-content-center">
                  <div>
                    <a href="../index.html">
                      <img
                        className="img-fluid w-75"
                        src="../assets/img/illustrations/404.svg"
                        alt="404 not found"
                      />
                    </a>
                    <h1 className="mt-5">
                      Page not{" "}
                      <span className="font-weight-bolder text-primary">
                        found
                      </span>
                    </h1>
                    <p className="lead my-4">
                      Oops! Looks like you followed a bad link. If you think
                      this is a problem with us, please tell us.
                    </p>
                    <Link to= "/">
                      <button
                        className="btn btn-primary animate-hover"
                        href="../../index.html"
                      >
                        <i className="fas fa-chevron-left mr-3 pl-2 animate-left-3" />
                        Go back home
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
