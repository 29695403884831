import React from 'react'

const Loading = () => {
    return (
        <div>
            <div className="preloader bg-soft flex-column justify-content-center align-items-center">
                <div className="loader-element">
                    <span className="loader-animated-dot" />
                    <img
                        src="../assets/img/brand/logo.png"
                        height={40}
                        alt="Impact logo"
                    />
                </div>
            </div>
        </div>
    )
}
export default Loading
