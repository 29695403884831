const Pengantar = {
    image: '../assets/img/all/owner.jpeg',
    header: 'Surya Anggraeni Psychology Center',
    content: "SYAPC atau Surya Anggraeni Psychology Center sebagai salah satu layanan psikologi yang ada di Yogyakarta, melakukan pelayanan psikologi klinis, psikologi perkembangan, psikologi pendidikan, psikologi industry dan psikologi forensic. Adapun layanan yang diberikan diantaranya adalah asesmen, konseling, asesmen (dasar, khusus), psikotes, psikoedukasi, konseling, psikoterapi, terapi anak (dasar, sedang, kompleks, komprehensif).",
}

const VisiMisi = {
    image: '',
    header: 'Visi Dan Misi',
    Visi: "Menjadi pusat layanan psikologi yang professional dan komprehensif dalam memberikan penanganan psikologis sepanjang rentang perkembangan dalam berbagai kehidupan.",
    Misi: "Memberikan layanan psikologis sesuai usia Memberikan layanan psikologis baik individu maupun kelompok secara tepat Memberikan layanan psikologis secara komprehensif sesuai kebutuhan Melakukan layanan psikologis sesuai standard dan prosedur yang berlaku dalam kode etik HIMPSI",
    Motto: "Memahami Kesehatan Mental Anda"
}

const Layanan = {
    header: 'Layanan Kami ',
    content: [
        {
            header: 'ASESMEN',
            photo: '../assets/img/all/ASESMEN.jpeg',
            content: 'Tes IQ, Potensi Psikologis, Kematangan Sekolah, Seleksi Siswa, Tumbuh Kembang ',
            icon: "far fa-clipboard"
        },
        {
            header: 'PSIKOTERAPI',
            photo: '../assets/img/all/KONSELING.jpeg',
            content: 'CBT, ACT, Transpersonal, Modifikasi Perilaku, Hypno-relaksasi, Mindfullness, Forgiveness',
            icon: "fas	fa-brain"
        },
        {
            header: 'KONSELING',
            photo: '../assets/img/all/KONSELING.jpeg',
            content: 'Pra Nikah, Keluarga, Tumbuh Kembang, Karir, Motivasi',
            icon: "fas	fa-comments"
        }
        ,
        {
            header: 'TRAINING',
            photo: '../assets/img/all/KONSELING.jpeg',
            content: 'Pranikah, Parenting, Pengembangan Diri, Exellent Service, Motivasi, Pembelajaran Efektif, Manajemen Emosi',
            icon: "fas	fa-graduation-cap"
        },
        {
            header: 'REKRUTMEN & SELEKSI ',
            photo: '../assets/img/all/REKRUTMEN & SELEKSI.jpeg',
            content: 'seleksi, promosi, mutasi, profiling, mapping',
            icon: "fas	fa-users"
        },
        {
            header: 'TERAPI KOMPREHENSIF ',
            photo: '../assets/img/all/KONSELING.jpeg',
            content: 'okupasi terapi, terapi wicara, drilling aquatic terapi',
            icon: "fas	fa-chalkboard-teacher"
        },

        {
            header: 'OUTBOUND / ',
            photo: '../assets/img/all/task.jpeg',
            content: '--',
            icon: "fas	fa-hiking"
        },
        {
            header: 'NAPZA ',
            photo: '../assets/img/all/KONSELING.jpeg',
            content: 'Rehabilitasi Rawat Jalan',
            icon: "fas	fa-chalkboard-teacher"
        },
        {
            header: 'PSIKOLOGI FORENSIK ',
            photo: '../assets/img/all/KONSELING.jpeg',
            content: 'Pemeriksaan Psokologi Forensik',
            icon: "fas	fa-chalkboard-teacher"
        },

    ],
}
const Teams = {
    image: '',
    header: 'PSIKOLOG KAMI HADIR DISINI ',
    content: [
        {
            name: 'JATU ANGGRAENI, S.Psi., M.Psi., Psikolog ',
            photo: '../assets/img/all/owner.jpeg',
            pendidikan: [{
                pendidikan: "S1 Psikologi UII",
            },
            {
                pendidikan: "S2 Magister  Profesi UAD"

            }],
            keahlian: "Anak Berkebutuhan Khusus, CBT, Transpersonal, Adiksi"
        },
        {
            name: 'NIA  KUSUMA WARDHANI, S.Psi., M.Psi., Psikolog',
            photo: '',
            pendidikan: [{
                pendidikan: "S1 Psikologi UII",
            },
            {
                pendidikan: "S2 Magister Profesi UGM"

            }],
            keahlian: "Rekruitmen & Asesmen, Training, Coaching  "
        },
        {
            name: 'RAHMA YUNAINI, S.Pd',
            photo: '../assets/img/all/owner.jpeg',
            pendidikan: [{
                pendidikan: "S1 Pendidikan Luar Biasa",
            },
            ],
            keahlian: "ASD, ADD/H, ABBS"
        },

    ]
}
const Alamat = {
    image: '../assets/img/doctor.jpg',
    header: 'Surya Anggraeni Psychology Center',
    jalan: 'Jalan kusumanegara no 159 kota madya yogyakarta Daerah Istimewa Yogyakarta 55281 Indonesia',
    content: 'Melayani dengan sepenuh Hati secara profesional dan andal, anda puas kami lemas',
    senin: " Senin: 08:00 AM – 4:45 PM ",
    selasa: 'Selasa : 08:00 AM – 4:45 PM',
    rabu: 'Rabu: 08:00 AM – 4:45 PM',
    kamis: 'Kamis: 08:00 AM – 4:45 PM',
    jumat: 'Jumat : 08:00 AM – 4:45 PM',
    sabtu: 'Sabtu: 08:00 AM – 4:45 PM',
    minggu: 'Minggu :Closed',
    contact: '(0274) 5018601',
    hp: '081325970906',
    email: 'sapcjogja@gmail.com'
}

const Paket = [{
    name: "Konsultasi Online",
    content: "Konsultasi dengan menggunakan media daring",
    time: "waktu maksimal 15-30 menit",
    child: [],
    price: "100.000",
    nb: "",
    active: true
}, {
    name: "Assesment Dasar",
    content: "Observasi dan Wawancara (tanpa psikotes), dipergunakan untuk menegakkan diagnose sementara, dan menentukan rancangan intervensi",
    time: "waktu maksimal 15-30 menit",
    child: [],
    price: "60.000",
    nb: "",
    active: false
}, {
    name: "Psikotes Sederhana",
    content: "Psikotes waktu maksimal 30 menit",
    time: "",
    child: [{
        name: "Psikotes Inteligensi (Raven, TIU, CFIT)",
        price: "130.000",
    },
    {
        name: "Tes Kepribadian dengan Inventory (BDI, BAI)",
        price: "130.000",
    }],
    price: "260.000",
    nb: "*Laporan Mencantumkan inteligensi dalam bentuk angka dan keterangan tanpa analisa mendalam",
    active: false
}, {
    name: "Psikotes Sedang",
    content: "Psikotes dengan 1-2 alat tes \n Waktu > 30-60 menit \n Psikotes dengan pembanding",
    time: "",
    child: [{
        name: "Psikotes Inteligensi dengan IST, TKD",
        price: "300.000",
    },
    {
        name: "Tes Kepribadian dengan EPPS, Papikostik, Grafis, Wartegg",
        price: "300.000",
    }],
    price: "600.000",
    nb: "*Laporan Psikologi dan rekomendasi",
    active: false
}, {
    name: "Psikotes Kompleks",
    content: "Gabungan beberapa alat test \n waktu >60-120 menit",
    time: "",
    child: [{
        name: "Psikotes Inteligensi (Weschler, Standford Binet, CAT, TAT)",
        price: "480.000",
    },
    {
        name: "Tes Minat Bakat Scolastik",
        price: "480.000",
    }],
    price: "960.000",
    nb: "*Laporan lengkap meliputi hasil assesmen, dinamika psikologis dan rekomendasi",
    active: false
}]

export { Pengantar, VisiMisi, Teams, Layanan, Alamat, Paket }