import React from 'react'
import Header from './Header'
import Content from './Content'
import Footer from './Footer'

const Layout = () => {
        return (
            <div>
                <Header/>
                <Footer/>
            </div>
        )
    }
export default Layout
