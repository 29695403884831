import React, { useState } from "react";
import { Container, Button, Link } from 'react-floating-action-button'
import { Alamat } from "../view/isi";

const Footer = () => {
  const [profile, setProfile] = useState({
    name: Alamat.header,
    address: Alamat.jalan,
    contact: Alamat.contact,
    hp: Alamat.hp,
    email: Alamat.email
  })
  return (
    <div>
      <footer className="footer section bg-zig-zag text-white ">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <a className="footer-brand mr-lg-5 d-flex" href="../../index.html">
                <img src="../assets/img/brand/logo.png" className="mr-3" alt="Footer logo" />
              </a>
              <p className="my-4">{profile.name}</p>
              <p className="my-4">{profile.address}</p>

            </div>
            <div className="col-6 col-sm-5 col-lg-4 mb-1 mb-lg-0">
              <h6>Hubungi Kami</h6>
              <ul className="links-vertical">
                <p className="my-4">Telp : {profile.contact}</p>
                <p className="my-4">HP:{profile.hp}</p>
                <p className="my-4">Email:{profile.email}</p>
                {/* <li><a target="_blank" href="https://themesberg.com/blog?ref=creative-tim-impact-pro-footer">Blog</a></li>
                <li><a target="_blank" href="https://themesberg.com/themes?ref=creative-tim-impact-pro-footer">Themes</a></li>
                <li><a target="_blank" href="https://themesberg.com/contact?ref=creative-tim-impact-pro-footer">Contact Us</a></li> */}
              </ul>
            </div>
            <div className="col-6 col-sm-5 col-lg-4 mb-1 mb-lg-0">
              <h6>JAM PELAYANAN</h6>
              <ul className="links-vertical">
                <li><a target="_blank">{Alamat.senin}</a></li>
                <li><a target="_blank">{Alamat.selasa}</a></li>
                <li><a target="_blank">{Alamat.rabu}</a></li>
                <li><a target="_blank">{Alamat.kamis}</a></li>
                <li><a target="_blank">{Alamat.jumat}</a></li>
                <li><a target="_blank">{Alamat.sabtu}</a></li>
                <li><a target="_blank">{Alamat.minggu}</a></li>
              </ul>
            </div>
            {/* <div className="col-12 col-sm-6 col-lg-4">
              <h6>Subscribe</h6>
              <p className="font-small">The latest Rocket news, articles, and resources, sent straight to your inbox every month.</p>
              <form action="#">
                <div className="form-row">
                  <div className="col-8">
                    <input type="email" className="form-control mb-2" placeholder="Email Address" name="email" required />
                  </div>
                  <div className="col-4">
                    <button type="submit" className="btn btn-secondary btn-block"><span>Subscribe</span></button>
                  </div>
                </div>
              </form>
              <small className="mt-2 form-text">We’ll never share your details. See our <a href="../pages/terms.html" className="font-weight-bold text-underline">Privacy Policy</a></small>
            </div> */}
          </div>
          {/* <hr className="my-4 my-lg-5" /> */}
          <div className="row">
            <div className="col pb-4 mb-md-0">
              <div className="d-flex text-center justify-content-center align-items-center">
                <p className="font-weight-normal mb-0">Copyright © <a href="https://themesberg.com" target="_blank">Kosim tecth 2021</a> <span className="current-year" />. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <Container>
        <Link href="https://wa.me/6281325970906"
          tooltip="Hubungi Kami"
          icon="fas fa-comment"
          className="bg-gold"
        />
        <Link href="/pendaftaran-online"
          tooltip="Pendaftaran Online"
          icon="fas fa-user-plus"
          className="fab-item btn btn-link btn-lg text-white bg-gold" />
        <Button
          tooltip="The big plus button!"
          icon="fas fa-plus"
          rotate={true}
          className="bg-gold"
        />
      </Container>
    </div >
  );
}
export default Footer
