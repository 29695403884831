import React from "react";
import { Link, NavLink } from "react-router-dom";
import Content from "./Content";
import { Link as Link1, animateScroll as scroll } from "react-scroll";
import { Nav, NavItem } from "react-bootstrap";
const Header = () => {
  return (
    <div className="header-global">
      <nav
        id="navbar-main"
        className="navbar navbar-main navbar-expand-lg headroom py-lg-3 px-lg-6 navbar-light navbar-theme-dark  sticky-top"
      >
        <div className="container">
          <div className="navbar-brand @@logo_classes" href="/">
            <img
              className="navbar-brand-dark common"
              src="../assets/img/brand/logo.png"
              height={50}
              alt="Logo light"
              width={80}

            />
            <img
              className="navbar-brand-light common"
              src="../assets/img/brand/logo.png"
              height={50}
              width={80}
              alt="Logo dark"
            />
          </div>
          <div className="navbar-collapse collapse" id="navbar_global">
            <div className="navbar-collapse-header">
              <div className="row">
                <div className="col-6 collapse-brand">
                  <div href="#">
                    <img
                      src="../assets/img/brand/logo.png"
                      height={35}
                      alt="Logo Impact"
                    />
                  </div>
                </div>
                <div className="col-6 collapse-close bg">
                  <div
                    href="#navbar_global"
                    role="button"
                    className="fas fa-times bg-white"
                    data-toggle="collapse"
                    data-target="#navbar_global"
                    aria-controls="navbar_global"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  />
                </div>
              </div>
            </div>
            <ul className="navbar-nav navbar-nav-hover justify-content-center">
              <li className="nav-item">
                <Link1
                  activeClass="active"
                  to="beranda"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <div className="nav-link text-white">Beranda</div>
                </Link1>
              </li>
              <li className="nav-item">
                <Link1
                  activeClass="active"
                  to="visimisi"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <div className="nav-link text-white">Visi Misi</div>
                </Link1>
              </li>
              <li className="nav-item">
                <Link1
                  activeClass="active"
                  to="layanan"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <div className="nav-link text-white">Layanan Kami</div>
                </Link1>
              </li>
              <li className="nav-item">
                <Link1
                  activeClass="active"
                  to="footer"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <div className="nav-link text-white">Hubungi Kami</div>
                </Link1>
              </li>
            </ul>
          </div>
          <Link to="pendaftaran-online">
            <div className="d-none d-lg-block @@cta_button_classes">
              <div
                // href="https://www.creative-tim.com/product/impact-design-system-pro"
                target="_blank"
                className="btn btn-md bg-gold animate-up-2 text-white"
              >
                <i className="fas fa-user-plus mr-2 text-white" /> Pendaftaran ONLINE
              </div>
            </div>
          </Link>
          <div className="d-flex d-lg-none align-items-center">
            <Link to="pendaftaran-online">

              <button
                className="navbar-toggler btn btn-md bg-gold animate-up-2 text-white"
                type="button"
                data-toggle="collapse"

              >
                <span> 
                  {/* <i className="fas fa-lock mr-2 text-white" /> Pendaftaran ONLINE  */}
                <i className="fas fa-user-plus"/>
                  
                  </span>
              </button>
            </Link>

          </div>
        </div>
      </nav>
      {/* <nav className="navbar fixed-bottom navbar-light bottom-tab-nav" role="navigation">
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">

            <NavItem key={`tab-${0}`}>
              <NavLink to={0} className="nav-link bottom-nav-link" activeClassName="active">
                <div className="row d-flex flex-column justify-content-center align-items-center">
                  <FontAwesomeIcon size="lg" icon={''}/>
                  <div className="bottom-tab-label">{'navbar bottom'}</div>
                </div>
              </NavLink>
            </NavItem>

          </div>
        </Nav>

      </nav> */}
      <div>
        <Content />
      </div>
    </div>
  );
}

export default Header